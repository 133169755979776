import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { graphql } from 'gatsby';

import { Main } from '~/layouts';
import { useDesigners, useCategories } from '~/data';
import { useAddItemToCart } from '~/hooks';
import { formatCurrency } from '~/utils/currency';
import { getIsLandscape } from '~/utils/cloudinary';
import { cleanMetaDescription } from '~/utils/string';
import { Seo } from '~/components/seo';
import { Subheadline } from '~/components/typography';
import { MarketingBreak } from '~/components/marketing';
import {
  Pdp,
  PdpMessage,
  PdpQuality,
  ProductCarousel,
} from '~/components/products';
import { Address } from '~/components/form';
import { Footer } from '~/components/footer';
import { Modal } from '~/components/modal';

const DEFAULT_FONT = 'openSans';
const QUANTITY = 1;

const CardPdpWrapper = ({ site, product }) => {
  const addItemToCart = useAddItemToCart();
  const collections = (product.collections || []).map(({ slug }) => slug);
  const { products: allProductsByDesigner } = useDesigners(product.vendorSlug);
  const { products: allProductsByCategory } = useCategories(collections);

  const productsByDesigner = allProductsByDesigner
    .filter(({ slug }) => slug !== product.slug)
    .splice(0, 6);

  const productsByDesignerSlugs = productsByDesigner
    .map(({ slug }) => slug);

  const productsByCategory = allProductsByCategory
    .filter(({ slug }) =>
      slug !== product.slug && productsByDesignerSlugs.indexOf(slug) < 0
    )
    .splice(0, 12);

  const isLandscape = getIsLandscape(product.images?.[0]);
  const description = cleanMetaDescription(product?.description);
  const { variants = [] } = product;
  const [isRecipientVisible, setIsRecipientVisible] = useState();
  const image = product.images?.[0]?.src;
  const sku = product.variants[0].sku || 'TGS';
  const priceDecimal = formatCurrency(
    product.priceRangeV2.minVariantPrice.amount,
  ).replace('$', '');
  const [message, setMessage] = useState("");
  const [font, setFont] = useState(
    DEFAULT_FONT,
  );
  const meta = [
    {
      name: 'og:image',
      content: image,
    },
  ];

  const onBuy = useCallback(async (recipient) => {
    const metadata = {};
    let type = '[Personalized]';

    if (message) {
      metadata.message = message;
      metadata.font = font;
    }

    if (recipient) {
      metadata.recipient = recipient;
    }

    if (isEmpty(metadata)) {
      type = '[Blank]';
    }

    try {
      addItemToCart({
        ...variants[0],
        ...product,
        title: `${type} ${product.title}`,
        metadata,
      });
      setMessage("");
      setFont(DEFAULT_FONT);
    } catch (err) {
      console.error(err);
    }
  }, [product, addItemToCart, font, message, variants]);

  const onRecipientPrompt = useCallback(
    () => setIsRecipientVisible(true),
    [setIsRecipientVisible],
  );

  const onRecipientCancel = useCallback(
    () => setIsRecipientVisible(false),
    [setIsRecipientVisible],
  );

  const onRecipientSave = useCallback((recipient) => {
    setIsRecipientVisible(false);
    onBuy(recipient);
  }, [onBuy, setIsRecipientVisible]);

  const onNext = () => {
    return message
      ? onRecipientPrompt()
      : onBuy();
  }

  return (
    <>
      <Seo
        title={product?.title}
        description={description}
        meta={meta}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "Product",
          "image": image,
          "name": product.title,
          "description": description,
          "sku": sku,
          "price": `${priceDecimal}`,
          "priceCurrency": "USD",
          "brand": {
            "@type": "Brand",
            "name": product.vendor,
          },
          "offers": {
            "@type": "Offer",
            "url": `${site.siteMetadata.siteUrl}/cards/${product.slug}`,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "http://schema.org/InStock",
            "price": `${priceDecimal}`,
            "priceCurrency": "USD",
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD",
            },
            "shippingDestination": [{
              "@type": "DefinedRegion",
              "addressCountry": "US",
            }],
          },
        }}
      />

      <Modal
        title="Where are we sending this?"
        isVisible={isRecipientVisible}
        onCancel={onRecipientCancel}
      >
        <Address
          onSubmit={onRecipientSave}
          onSkip={onRecipientSave}
        />
      </Modal>

      <Pdp
        {...{
          product,
          isLandscape,
          setMessage,
          onNext,
        }}
      />
      <PdpMessage
        {...{
          font,
          setFont,
          message,
          setMessage,
          quantity: QUANTITY,
          isLandscape,
          onNext,
        }}
      />
      <ProductCarousel
        title={`More from ${product.vendor}`}
        ctaPath={`/designers/${product.vendorSlug}`}
        products={productsByDesigner}
      />
      <ProductCarousel
        title="Other Cards like This One"
        products={productsByCategory}
      />
      <PdpQuality />
      <MarketingBreak>
        <Subheadline>
          Ordering in Bulk? <a href={`mailto:hello@thegoodsnail.com?subject=Bulk Order Inquiry: ${product?.title}`}>Reach out.</a>
        </Subheadline>
      </MarketingBreak>
      <Footer />
    </>
  );
}

const CardPage = ({
  data: {
    site,
    shopifyProduct: product,
  },
}) => (
  <Main fullView>
    <CardPdpWrapper {...{ site, product }} />
  </Main>
);

export const query = graphql`
  query($handle: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    shopifyProduct(
      handle: { eq: $handle },
      status: { eq: "ACTIVE" },
    ) {
      id
      shopifyId
      slug: handle
      collections {
        slug: handle
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      variants {
        id
        shopifyId
        price
        availableForSale
        displayName
        sku
        title
      }
      images {
        id
        altText
        originalSrc
        src
        width
        height
      }
      title
      description
      descriptionHtml
      productType
      tags
      status
      vendor
      vendorSlug
    }
  }
`

export default CardPage;
